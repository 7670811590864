<template>
    <div class="tw-h-full">
        <h3 class="tw-text-xs tw-uppercase tw-text-blue-700">
            {{ $t('productProfile.productPhoto') }}
        </h3>
        <form class="form-horizontal">
            <div class="row">
                <div class="col-md-12">
                    <ts-image-input
                        :file="model.photo ? model.photo : file"
                        @input="file => (model.photo = file)"
                        :width="410"
                        :height="378"
                        crop
                    ></ts-image-input>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
export default {
    name: 'photo',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            file: require('@/assets/default-product_flat.jpg'),
            model: {}
        }
    },
    created () {
        this.model = this.value
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
