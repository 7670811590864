<template>
    <div style="height: 31.4%">
        <h3 class="tw-text-xs tw-uppercase tw-text-blue-700">
            {{ $t('productProfile.addOnItem') }}
        </h3>
        <div class="tw-space-y-2">
            <Select
                v-model="model.recipes"
                :filter-by-label="true"
                :placeholder="$t('select')"
                multiple
                :clearable="true"
                filterable
            >
                <Option
                    v-for="recipe in recipeList"
                    :value="recipe.pro_sale_code_id"
                    :key="recipe.pro_sale_code_id"
                >
                    {{
                        recipe.pro_group_name +
                        '-' +
                        recipe.product_name_en +
                        '-' +
                        recipe.sale_code
                    }}
                </Option>
            </Select>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'

export default {
    name: 'addOnItem',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {}
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('product/profile', ['formViewDatas']),
        // branchList () {
        //     return this.formViewDatas.branch
        // }
        recipeList () {
            return this.$store.state.product.profile.productRecipe
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
