<template>
    <div>
        <h3 class="tw-text-xs tw-uppercase tw-text-blue-700">
            {{ $t('productProfile.productSaleCode') }}
        </h3>
        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('productProfile.uom') }}</th>
                    <th>{{ $t('productProfile.saleCode') }}</th>
                    <th>{{ $t('productProfile.cost') }}</th>
                    <th>{{ $t('productProfile.quantityInHand') }}</th>
                    <!-- <th class="tw-w-4">{{ $t('productProfile.grade') }}</th>
          <th class="tw-text-right">{{ $t('productProfile.dealerPrice') }}</th> -->
                    <th class="ts-text-right">
                        {{ $t('productProfile.salePrice') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(code, index) in model.product_sale_code"
                    :key="index"
                >
                    <td>
                        {{ code.uom_name }}
                    </td>
                    <td>
                        <input
                            type="text"
                            v-model="code.sale_code"
                            class="tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none"
                            :class="{
                                'tw-border-red-600': errors.has(
                                    'product_sale_code.' + index + '.sale_code'
                                )
                            }"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            v-model="code.cost"
                            class="tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none"
                            :class="{
                                'tw-border-red-600': errors.has(
                                    'product_sale_code.' + index + '.cost'
                                )
                            }"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            v-model="code.quantity_in_hand"
                            class="tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none"
                            :class="{
                                'tw-border-red-600': errors.has(
                                    'product_sale_code.' +
                                        index +
                                        '.quantity_in_hand'
                                )
                            }"
                        />
                    </td>
                    <!-- <td>
            <input
              type="text"
              v-model="code.grade"
              class="tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none tw-w-16"
              :class="{
                'tw-border-red-600': errors.has(
                  'product_sale_code.' + index + '.grade'
                ),
              }"
            />
          </td> -->
                    <!-- <td class="tw-text-right">
            <input
              type="number"
              v-model="code.dealer_price"
              class="
                tw-border
                tw-px-2
                tw-py-1
                tw-rounded-md
                tw-outline-none
                tw-text-right
								tw-w-32
              "
              :class="{
                'tw-border-red-600': errors.has(
                  'product_sale_code.' + index + '.dealer_price'
                ),
              }"
            />
          </td> -->
                    <td>
                        <input
                            type="number"
                            v-model="code.sale_price"
                            class="tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none tw-text-right tw-w-32"
                            :class="{
                                'tw-border-red-600': errors.has(
                                    'product_sale_code.' + index + '.sale_price'
                                )
                            }"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
export default {
    name: 'proSaleCode',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {}
        }
    },
    created () {
        this.model = this.value
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
